<template>
  <div class="layout-padding">
    <div class="layout-padding-auto layout-padding-view">
      <el-table
          :data="data.tableData"
          style="width: 100%"
      >
        <el-table-column prop="landInfo.title" label="标题" show-overflow-tooltip />
        <el-table-column prop="landInfo.regionId" label="所属行政区" show-overflow-tooltip>
          <template #default="scope">
            {{ findLabel(land_area, scope.row.landInfo.regionId) }}
          </template>
        </el-table-column>
        <el-table-column prop="landInfo.ownerOrg" label="土地权属单位" width="120"  show-overflow-tooltip />

        <el-table-column prop="amount" label="交易总金额" show-overflow-tooltip>
          <template #default="scope"> {{ scope.row.amount || 0 }} 万元 </template>
        </el-table-column>
        <el-table-column prop="landInfo.contact" label="出租方">
          <template #default="scope">
            <UserTag :userId="scope.row.sellerId" />
          </template>
        </el-table-column>
        <el-table-column prop="landInfo.contact" label="承租方">
          <template #default="scope">
            <UserTag :userId="scope.row.buyerId" />
          </template>
        </el-table-column>
        <el-table-column prop="transactionStatus" label="交易状态" show-overflow-tooltip>
          <template #default="scope">
            {{ findLabel(agreement_transaction_status, scope.row.transactionStatus) }}
          </template>
        </el-table-column>

        <el-table-column prop="updateTime" label="操作时间" show-overflow-tooltip />
        <el-table-column label="操作" width="180">
          <template #default="scope">
            <el-button icon="edit-pen" text type="primary" v-if="scope.row.transactionStatus !== 'finish'"  @click="onSendApply(scope.row)">上传资料</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="user-info-list-page">
        <el-pagination background layout="prev, pager, next" :page-count="data.totalPage" :current-page="data.currentPage" @current-change="pageChange">
        </el-pagination>
      </div>
      <agreement-trade-files-dialog ref="agreementTradeFilesDialogRef" @refresh="pageChange" />
    </div>

    <!-- 交易双方  -->
<!--    <el-dialog v-model="selectTargetDialogVisible" title="上传交易双方身份材料" width="400px">-->
<!--      <el-form>-->
<!--        <el-form-item label="交易方" prop="target">-->
<!--          <el-radio-group v-model="selectTarget">-->
<!--            <el-radio v-for="item in transaction_person_type" :key="item.value" :label="item.value" border>{{ item.label }}</el-radio>-->
<!--          </el-radio-group>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--      <template #footer>-->
<!--				<span class="dialog-footer">-->
<!--					<el-button @click="selectTargetDialogVisible = false">取消</el-button>-->
<!--					<el-button type="primary" @click="onSelectTarget">确认</el-button>-->
<!--				</span>-->
<!--      </template>-->
<!--    </el-dialog>-->
<!--    &lt;!&ndash; 编辑、新增  &ndash;&gt;-->
<!--    <land-selector-dialog ref="landSelectorDialogRef" :default-query-params="defaultLandQueryParams" @refresh="getDataList(false)" />-->
<!--    <form-dialog ref="formDialogRef" @refresh="getDataList(false)" />-->
<!--    <file-dialog ref="fileDialogRef" @refresh="getDataList(false)" />-->
  </div>
</template>

<script setup  name="agreementTrade">
import {reactive, ref, defineAsyncComponent, onMounted} from 'vue'
import {Message, MessageBox} from 'element-ui'
import { useDict, findLabel} from '@/hooks/dict';
import UserTag from '@/components/UserTag.vue';
import CommonService from "@/service/CommonService";

const AgreementTradeFilesDialog = defineAsyncComponent(() => import('@/components/DemandTradeFiles.vue'));

const agreementTradeFilesDialogRef = ref();

const data = reactive({
  pageSize:10,
  currentPage:1,
  totalPage:1,
  tableData: [
  ]
})


// 引入组件
// const LandSelectorDialog = defineAsyncComponent(() => import('./../land_info/land_selector.vue'));
// const FormDialog = defineAsyncComponent(() => import('./form.vue'));
// const FileDialog = defineAsyncComponent(() => import('./../land_info/files.vue'));
// 定义查询字典

const { agreement_transaction_status, transaction_way, land_area, land_purpose, land_owner_type, transaction_person_type } = useDict(
    'agreement_transaction_status',
    'transaction_way',
    'land_area',
    'land_purpose',
    'land_owner_type',
    'transaction_person_type'
);


const formDialogRef = ref();

const onSendApply = async (params) => {

  agreementTradeFilesDialogRef.value.openDialog(params, 'rent');
};


const pageChange = (page) =>{
  loadData(page);
}

onMounted(() => {
  loadData(1);
})
const loadData = (page) =>{

  CommonService.getRentTransactionList({current:page, auditStatus: 2,my: 1}).then((res)=>{
    if(res.code === 0){
      data.tableData = res.data.records;
    }
  })
}





</script>
