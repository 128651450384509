<template>
  <span>{{ user[field] }}</span>
</template>

<script>
import CommonService from "@/service/CommonService";

export default {
  props: {
    userId: {
      default: ''
    },
    field: {
      default: 'name'
    },
  },
  created() {
    this.getUser();
  },
  data() {
    return {
      user: {
        name:''
      }
    }
  },
  methods: {
    getUser() {
      CommonService.getUserInfoById(this.userId).then((res) => {
        Object.assign(this.user, res.data);
        this.user = res.data;
      });
    },
  },
}
</script>
